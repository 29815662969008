<template>
  <div>
    <v-expansion-panel-header class="primary"
      ><h4 class="mx-2 mb-0 white--text">
        {{ $t("message.PaymentOptions") }}
      </h4></v-expansion-panel-header
    >
    <v-expansion-panel-content>
      <div>
        <v-tabs light slider-color="primary" v-model="activePayment">
          <v-tab class="text-capitalize">{{ $t("message.MobileMoney") }}</v-tab>
          <v-tab class="text-capitalize">{{ $t("message.PaywithCash") }}</v-tab>
          <v-tab class="text-capitalize">{{
            $t("message.Paywithdebit")
          }}</v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text class="pa-0">
                <div class="header text-center bg-grey pt-12 pb-4">
                  <div class="mb-6">
                    <img alt="discount" src="/static/images/card.png" />
                  </div>
                  <h4 class="mb-12 font-weight-medium">
                    {{ $t("message.PayWithMobileMoney") }}
                  </h4>
                </div>

                <v-form
                  class="form-wrapper pt-6 text-center py-12"
                  ref="form"
                  v-model="valid"
                >
                  <div class="layout row wrap">
                    <v-flex xs12 sm12 md12 lg8 xl8>
                      <div class="layout row wrap">
                        <!-- <v-btn class="sidebar-toggle mx-4" color="accent" dark @click="showOrder()" >Show Order Detail</v-btn> -->
                        <v-flex xs12 sm6 md6 lg3 xl3>
                          <v-text-field
                            label="Mobile"
                            v-model="payPhone"
                            placeholder="Mobile"
                            outlined
                            dense
                          ></v-text-field>
                          <!-- <v-text-field class="mt-0 pt-0" :rules="inputRules.basictextRules" light label="Expiry Date(01/10)" required></v-text-field> -->
                        </v-flex>
						<h4>
							<v-divider class="my-3"></v-divider>
                <emb-currency-sign></emb-currency-sign>
                {{ (getTotalPrice / currentValue).toFixed(2) }}
              </h4>
                        <v-flex xs12 sm12 md12 lg12 xl12>
                          <div class="text-xl-left text-sm-left">
                            <v-btn
                              :loading="loading"
                              class="accent mr-3"
                              @click="makePayment('MOMO')"
                              >{{ $t("message.Submit") }}</v-btn
                            >
                            <v-btn @click="makeCleat">{{
                              $t("message.Clear")
                            }}</v-btn>
                          </div>
                        </v-flex>
                      </div>
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      md12
                      lg3
                      xl2
                      hidden-md-and-down
                      d-flex
                      justify-center
                      align-center
                      card-visibility
                    >
                      <img
                        src="/static/images/credit-card.png"
                        slt="Credit card"
                        width="128"
                        height="128"
                      />
                    </v-flex>
                  </div>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text class="pa-0">
                <div class="header text-center bg-grey pt-12 pb-4">
                  <div class="mb-6">
                    <img alt="Bank" src="/static/images/online-shop.png" />
                  </div>
                  <h4 class="mb-12 font-weight-medium">
                    {{ $t("message.PaywithCash") }}
                  </h4>
                </div>
                <v-divider class="mt-12 mb-2"></v-divider>
                <div class="text-center">
                  <!-- <v-btn class="sidebar-toggle mx-4" color="accent" dark @click="showOrder()" >Show Order Detail</v-btn> -->
                  <v-btn
                    class="sidebar-toggle mx-4"
                    color="accent"
                    dark
                    @click="makePayment('CASH')"
                    >{{ $t("message.Submit") }}</v-btn
                  >
                  <v-btn @click="makeCleat">{{ $t("message.Clear") }}</v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text class="pa-0">
                <div class="header text-center bg-grey pt-12 pb-4">
                  <div class="mb-6">
                    <img alt="Bank" src="/static/images/online-shop.png" />
                  </div>
                  <h4 class="mb-12 font-weight-medium">
                    {{ $t("message.Paywithdebit") }}
                  </h4>
                </div>
                <v-divider class="mt-12 mb-2"></v-divider>
                <div class="text-center">
                  <!-- <v-btn class="sidebar-toggle mx-4" color="accent" dark @click="showOrder()" >Show Order Detail</v-btn> -->
                  <v-btn
                    class="sidebar-toggle mx-4"
                    color="accent"
                    dark
                    @click="makePayment('DEBT')"
                    >{{ $t("message.Submit") }}</v-btn
                  >
                  <v-btn @click="makeCleat">{{ $t("message.Clear") }}</v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-expansion-panel-content>
  </div>
</template>

<script>
// import order from "Api/order";
import { mapGetters } from "vuex";
// import accessToken from "Api/accessToken";
export default {
  computed: {
    ...mapGetters([
      "stepOneFormValid",
      "cart",
      "drawer",
      "userDetails",
	  "payPhone",
      "totalPrice",
      "shipping",
	  "tax",
	   "selectedCurrency"
    ]),
	itemTotal() {
      let productTotal = null;
      if (this.cart.length > 0) {
        for (const item of this.cart) {
          productTotal += item.total * item.quantity;
        }
        return productTotal.toFixed(2);
      } else {
        return productTotal.toFixed(2);
      }
    },
    /**
     * method for calculatig subtotal of  item
     */
    getTotalPrice() {
      let totalPrice = this.tax + this.shipping;
      if (this.cart.length > 0) {
        for (const item of this.cart) {
          totalPrice += item.total * item.quantity;
        }
        return totalPrice.toFixed(2);
      } else {
        return totalPrice.toFixed(2);
      }
    },
	formattedTotal() {
      const totalPrice = this.getTotalPrice() / this.currentValue;
      return totalPrice.toFixed(2);
    }
  },
  data() {
    return {
      activePayment: null,
      valid: false,
      inputRules: {
        basictextRules: [(v) => !!v || "This field should not be empty."]
      },
      val: "",
      bankListing: ["Option1", "Option2", "Option3", "Option4"],
      invoiceData: {
        products: [],
        firstName: "",
        lastname: "",
        email: "",
        streetNumber: "",
        city: "",
        country: "",
        agentCode: "",
        paymentsOption: "",
        MOMOPhoneNumber: "",
        totalAmmount: ""
      },
	  selectDeletedProduct: null,
      drawer: null,
      items: [
        { title: "Home", icon: "dashboard" },
        { title: "About", icon: "question_answer" }
      ],
      currentValue: 1,
      code: 783729873429,
      total: 0,
      product: [],
      loading: false
    };
  },
  methods: {
    makeCleat() {
      location.reload();
	  console.log("agygy"
)
    },
    /**
     * This Function is to make Payment
     */
    async makePayment(option) {
      this.loading = true;
      try {
        this.$refs.form.validate();
        if (this.valid) {
          this.cart.forEach((el) => {
            this.product.push({
              _id: el.id,
              quantity: el.quantity
            });
            this.total += el.total * el.quantity;
          });
          this.invoiceData = {
            firstName: this.userDetails.firstName,
            lastName: this.userDetails.lastName,
            email: this.userDetails.email,
            streetNumber: this.userDetails.streetName,
            city: this.userDetails.cityState,
            country: this.userDetails.country,
            agentCode: this.code,
            paymentOption: option,
            phoneNumber: this.userDetails.phone,
            MoMoPhoneNumber: this.payPhone,
            totalAmmount: this.getTotalPrice,
            products: this.product,
            delivery: this.shipping
          };

    const token = localStorage.getItem('collectionToken');
    // let message = "";
    // const disbursementToken = localStorage.getItem('disbursmentToken');
    if(token){

      let phone = this.invoiceData.phoneNumber;

      const numericInput = phone.replace(/\D/g, '');
      // Check if the input starts with '0780'
      if (numericInput.startsWith('0780')) {
        // If it does, return the normalized number
        phone = '0780' + numericInput.slice(4);
      }

      // If not, check if the input starts with '+250' and remove it
      if (numericInput.startsWith('250')) {
        phone = '0' + numericInput.slice(3);
      }

    if(this.invoiceData.totalAmmount > 1000000){
      this.$snotify.error(`Please enter amount less than 1M`, {
        closeOnClick: false,
        pauseOnHover: false,
        timeout: 3000
      });
      this.loading = false;
      return
    }

    console.log("this is phone number",phone);

  //  await fetch("https://byihuse-0c0k.onrender.com", {
   await fetch("https://byihuse-0c0k.onrender.com/routes/pay/request", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
    amount : parseInt(this.invoiceData.totalAmmount, 10),
    phone: phone,
    token: token
  })
})
  .then((response) => {
    if (!response.ok) {
              this.$snotify.error(`Something is wrong! please try again`, {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 3000
        });
      throw new Error(`Network response was not ok: ${response.status}`);
    }
this.$snotify.success(`Check your mobile phone and proceed with payment!`, {
        closeOnClick: false,
        pauseOnHover: false,
        timeout: 3000
      });
    return response.json();
  })
  .then((data) => {
   if(data.message == "success") {
    localStorage.setItem('transactionId', data.transactionId);
    this.checkPaymentStatus();
  }
  })
  .catch((error) => {
    console.log(error);
  });
  }

if (token) console.log("this is token",token.access_token);
          // const res = await order.makeOrder(this.invoiceData);
          // if (option === "CASH" || option === "DEBT") {
          //   this.$router.push(`/${this.$i18n.locale}/session/thank-you`);
          // } else {
          //   location.replace(res.data.data.payment.meta.authorization.redirect);
          // }
          localStorage.removeItem("cart");
          this.loading = false;
        }
      } catch (err) {
        // this.$snotify.error(`We are having issues! please try again soon`, {
        //   closeOnClick: false,
        //   pauseOnHover: false,
        //   timeout: 3000
        // });
        console.log(err);
      }
      this.loading = false;
    },

  async checkPaymentStatus() {
    const token = localStorage.getItem('collectionToken');
    const disbursmentToken = localStorage.getItem('disbursmentToken'); 
    const intervalId = setInterval(async () => {
      try {
        const transactionId = localStorage.getItem('transactionId');
        console.log("this is transaction id from check payment function",transactionId)
        if (transactionId) {
          const response = await fetch(`https://byihuse-0c0k.onrender.com/routes/pay/requestStatus/${transactionId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },    body: JSON.stringify({
token: token
  })
          });

          if (response.ok) {
            const data = await response.json();
            console.log("this is data from check payment function",data);
            if (data.response.status === 'FAILED') {
              clearInterval(intervalId); // Stop the interval once the payment is successful
              this.$snotify.error('your payment was not processed!', {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 3000,
              });
            } else if (data.response.status === 'SUCCESSFUL') {
              try {
                const disbursementResponse = await fetch(`https://byihuse-0c0k.onrender.com/routes/pay/transfer`, {
               method: 'POST',
               headers: {
                 'Content-Type': 'application/json',
               },
               body: JSON.stringify({
                 amount: data.response.amount,
                 phone: [{ phone: `${data.response.payer.partyId}`, percent: 20 }],
                 token: disbursmentToken,
               }),
             });
             console.log(disbursementResponse);
             console.log("successful")

           if (!disbursementResponse.ok) {
                throw new Error(`Error: ${disbursementResponse.status} - ${disbursementResponse.statusText}`);
             }
          if (disbursementResponse.ok) {
            const disbursementData = await disbursementResponse.json();
            console.log("this is data from check payment function",disbursementData);
            if (disbursementData.message === 'success') {
              clearInterval(intervalId); // Stop the interval once the payment is successful
              this.$snotify.success('thank you!! Finvia group has sent you a bonus!', {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 3000,
              });
            }
          }
              clearInterval(intervalId); // Stop the interval once the payment is successful
              this.$snotify.success('thank you!! Finvia group has sent you a bonus!', {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 3000,
              });
              } catch (error) {
                console.log("error from sending amount : ", error);
              }
            }
           else if (data.response.status === 'PENDING') { // Stop the interval once the payment is successful
              this.$snotify.error('press *182*7*1# to approve the transaction!!', {
                closeOnClick: false,
                pauseOnHover: false,
                timeout: 3000,
              });
            console.error(`Error: ${response.status} - ${response.statusText}`);
          }}}
      } catch (error) {
        console.error(error);
      }
    }, 30000);
    this.$data.paymentStatusIntervalId = intervalId;
  },


    showOrder() {
      // this.drawer = true
      this.$store.commit({
        type: "showOrdervu",
        count: "hsdjds"
      });
      //  console.log()
    }
	
  }
};
</script>
