<template>
   <div class="emb-payment-wrap">
		<emb-page-title heading="message.PaymentInformation"></emb-page-title>
		<div class="emb-payment-content section-gap overflow-x-hidden">
			<v-container grid-list-xl py-0>
				
				<template v-if="cart.length > 0">
					<div class="delivery" style="margin-bottom: 30px">
							<v-btn style="min-width: 45%; height: 60px" @click="freedelivery" :class="{accent: attached}">{{$t("message.FreeDelivery")}} <small>({{$t("message.productsinfewhours")}})</small></v-btn>
							<v-btn style="min-width: 54%; height: 60px" id="premium" @click="premiumdelivery" :class="{accent: !attached}">{{$t("message.PremiumDelivery")}} - <emb-currency-sign></emb-currency-sign>{{(2000/currentValue).toFixed(2)}} <small>({{$t("message.getorderrightaway")}})</small></v-btn>
						</div>
					<div class="deliveryNavigation">
						
						<div class="Navigation">
							<emb-sidebar-panel class="mb-12"></emb-sidebar-panel>	
						</div>
					</div>
					<v-expansion-panels v-model="panel" multiple >
						<v-expansion-panel>
							<shipping-address @changeStepOneForm="enableStepOneForm"></shipping-address>
						</v-expansion-panel>
						<v-expansion-panel :disabled="!stepOneFormValid" :class="{'contracted': !stepOneFormValid}">
							<promo-codes @changePanel="changePanelValue"></promo-codes>
						</v-expansion-panel>
						<v-expansion-panel :disabled="!stepOneFormValid" :class="{'contracted': !stepOneFormValid}">
							<payment-option></payment-option>
						</v-expansion-panel>
					</v-expansion-panels>	
				</template>
				<template v-else>
					<div class="text-center">
						<div class="mb-6">
							<img  alt="cart-empty" height="128" src="/static/images/empty-cart.png" width="128">
						</div>
						<h4 > {{$t("message.YourShoppingBagisempty")}}</h4>
						<router-link class="primary--text" :to="'/'+$i18n.locale+'/products'">{{$t("message.GotoShopping")}}</router-link>
					</div>
				</template>
			</v-container>
		</div>
   </div>
</template>

<script>
import ShippingAddress from './Component/ShippingAddress'
import PromoCodes from './Component/PromoCodes'
import PaymentOption from './Component/PaymentOption'
import currency from "Api/currency";
import { mapGetters } from 'vuex';

export default {
	data(){
		return{
			stepOneFormValid: false,
			expansionPanel: [0],
			attached: true,
			currentValue:1
		}
	},
	components:{
		shippingAddress:ShippingAddress,
		promoCodes:PromoCodes,
		paymentOption:PaymentOption
	},
	computed: {
		...mapGetters(["cart","selectedCurrency", "drawer"]),
		panel: {
			get() {
				return this.expansionPanel;
			},
			set(val) {
				if(val.includes(0)){
					this.expansionPanel = [0];
					this.stepOneFormValid = false;
				}else if(val.includes(1) && this.expansionPanel != 1){
					this.expansionPanel = [1];
				}else if(val.includes(2)){
					this.expansionPanel = [2];
				}else{
					this.expansionPanel= [];
				}
			}
		}
	},
	async mounted (){
			try {
				const res= await currency.getcurrency()
				res.data.data.forEach(el => {
					if (this.selectedCurrency.symbol === el.symbol) {
						this.currentValue= el.currentValue
					}
				});
				// console.log(res)
			} catch (err) {
				console.log(err.response.message)
			}
	},
	methods: {
		enableStepOneForm(){
			this.stepOneFormValid = true;
			this.expansionPanel = [1];
		},
		changePanelValue(){
			this.expansionPanel = [2];
		},
		freedelivery(){
			this.attached= true
			this.$store.state.shipping= 0
		},
		premiumdelivery(){
			this.attached= false
			this.$store.state.shipping= 2000
			// console.log('helo')
		}
	}
}
</script>
<style  scoped>
.deliveryNavigation{
	display: flex;
	/* justify-content: end; */
}
.delivery{
	flex: 1;
}
.Navigation{
	flex: 1;
}

</style>

<!-- <template>
	<div class="emb-contact-wrap">
	  <emb-page-title
		heading="Payment"
		subHeading="Please fill your phone number to pay"
	  >
	  </emb-page-title>
	  <div class="emb-contact-content">
		<div class="contact-info-wrapper">
		  <v-container grid-list-xl py-0>
			<div class="section-gap">
			  <v-layout row wrap>
				<v-flex sm12 md12 lg7 xl7>
				  <div class="sec-title">
					<h2>Fill phone to Pay</h2>
				  </div>
				  <v-form ref="form" v-model="valid">
					<v-text-field
					  v-model="telephoneNumber"
					  type="text"
					  :placeholder="label.telephoneNumber"
					  :rules="inputRules.basictextRules"
					></v-text-field>
					<v-text-field
					  v-model="amount"
					  type="text"
					  :placeholder="label.amount"
					  :rules="inputRules.basictextRules"
					></v-text-field>
					<v-btn
					  :loading="loading"
					  class="accent mx-0 mt-4"
					  large
					  @click.stop.prevent="payAndFetchUuid"
					>
					  Pay
					</v-btn>
				  </v-form>
				</v-flex>
			  </v-layout>
			</div>
		  </v-container>
		</div>
	  </div>
	</div>
  </template>
  
  <script>
  import payment from "Api/payment";
  import getUuid from "Api/uuid";
  import getAccessToken from "Api/accessToken";
  import requestPay from "Api/requestToPay";
  import transactionStatus from "Api/transactionStatus";
  import transferAmount from "Api/transferAmount"
  import { mapGetters } from "vuex";
  
  export default {
	computed: {
	  ...mapGetters(["selectedLocale"])
	},
	data() {
	  return {
		loading: false,
		telephoneNumber: "",
		amount: "",
		label: {},
		inputRules: {
		  basictextRules: [v => !!v || "This field should not be empty"]
		},
		valid: false
	  };
	},
	methods: {
	  async getPayment() {
		this.loading = true;
		try {
		  const res = await payment.postPayment({
			telephoneNumber: this.telephoneNumber,
			amount: this.amount
		  });
		  console.log("this is payment ", res);
		  this.$snotify.success(`Check your phone`, {
			closeOnClick: false,
			pauseOnHover: false,
			timeout: 1000,
			showProgressBar: false
		  });
		  this.loading = false;
		} catch (err) {
		  console.log(err);
		  this.loading = false;
		}
	  },
	  async getUuid() {
		this.loading = true;
		try {
		  const uuid = await getUuid.getUuid();
		  console.log("UUID: ", uuid.data);
		  this.loading = false;
		} catch (err) {
		  console.log(err);
		  this.loading = false;
		}
	  },
	  async getAccessToken() {
      try {
		console.log("starting access token")
        const accessToken = await getAccessToken.getAccessToken();
        console.log("Access Token: ", accessToken.data.access_token);
		localStorage.setItem("access_Token",accessToken.data.access_token)
        // Do something with the access token
      } catch (err) {
        console.log(err);
      }
    },
	async requestToPay() {
      try {
        this.loading = true;
		let accessToken = localStorage.getItem("access_Token")
		if(!accessToken) accessToken = process.env.VUE_APP_ACCESS_TOKEN
        console.log(accessToken)
        console.log(process.env.VUE_APP_ACCESS_TOKEN)
        //Request to get UUID
		console.log("starting request")
        const referenceId = await getUuid.getUuid();
		localStorage.setItem("x-id", referenceId)
		console.log(referenceId)

        //Request to get Access Token

        let x_referenceId = localStorage.getItem("x-id")
        //Make payment request from momo ...............
        const paymentResponse = await requestPay.payRequest(
			x_referenceId ? x_referenceId : referenceId.data,
          this.amount,
          this.telephoneNumber,
          accessToken
        );

        console.log("Payment Response: ", paymentResponse);
        console.log("UUID: ", referenceId.data);

        this.$snotify.success(`Check your phone`, {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 1000,
          showProgressBar: false
        });

        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
	async paymentStatus() {
      try {
        this.loading = true;
		let accessToken = localStorage.getItem("access_Token")
		if(!accessToken) accessToken = process.env.VUE_APP_ACCESS_TOKEN
        console.log(accessToken)
        console.log(process.env.VUE_APP_ACCESS_TOKEN)

        let x_referenceId = localStorage.getItem("x-id")
        //check payment request from momo ...............
        const paymentResponseStatus = await transactionStatus.checkTransactionStatus(
			x_referenceId,
          accessToken
        );
		if(paymentResponseStatus.status == "SUCCEED"){
			const referenceId = await getUuid.getUuid();
			// let peopleToRecieve = [this.telephoneNumber,"0787925736"];
		await transferAmount.sendAmount(
			referenceId.data,
          (this.amount*18)/100,
          this.telephoneNumber,
          accessToken
        );
		await transferAmount.sendAmount(
			referenceId.data,
          (this.amount*20)/100,
          "0787925736",
          accessToken
        );
		}
		console.log("Payment Response: ", paymentResponseStatus);
        this.$snotify.success(`Check your phone`, {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 1000,
          showProgressBar: false
        });

        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async payAndFetchUuid() {
      // Call all three functions sequentially
    //   await this.getPayment();
    //   await this.getUuid();
      await this.getAccessToken();
	  await this.requestToPay();
    },
	  saveDetails() {
		this.$refs.form.validate();
	  }
	},
	mounted() {
	  const data = JSON.parse(localStorage.getItem("data"));
	  if (data) {
		this.telephoneNumber = data.telephoneNumber;
		this.amount = data.amount;
	  }
	  if (this.selectedLocale.name === "English") {
		this.label = {
		  telephoneNumber: "phone number",
		  amount: "amount"
		};
	  } else {
		this.label = {
		  telephoneNumber: "tel",
		  amount: "amount",
		  Subject: "Sujet*",
		  address: "Votre adresse",
		  message: "Laisser un message*",
		  email: "Email*"
		};
	  }
	}
  };
  </script>
   -->